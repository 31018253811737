<template>
  <div>
    <section>
      <div class="container">
        <h1 class="text-center">
          Need professional translations?<br/>We’ll be happy to assist!
        </h1>
        <p class="text-center">Get in touch with us by filling out the form below.<br/>We’ll contact you back as soon as possible.</p>
        <div class="contact-info">
          <div>
            <h3>Phone</h3>
            <a href="tel:+6620066216">+66 (0)20 066 216</a>
          </div>
          <div>
            <h3>Email</h3>
            <a href="mailto:projects@wallacesolutions.net">projects@wallacesolutions.net</a>
          </div>
          <div>
            <h3>Facebook</h3>
            <a href="#">Wallace Solutions Co., Ltd.</a>
          </div>
          <div>
            <h3>Chat</h3>
            <p>Talk to us directly on this site, via the text balloon in the bottom corner</p>
          </div>
        </div>
        <div class="or-divider">
          <span>OR</span>
        </div>
        <div class="form-container">
          <form accept-charset="UTF-8" action="https://usebasin.com/f/678addfc7c37" enctype="multipart/form-data" method="POST">
            <div>
              <input type="text" class="form" placeholder="First Name" name="firstName" required>
              <input type="text" class="form" placeholder="Last Name" name="lastName">
            </div>
            <div>
              <input type="text" class="form" placeholder="Email Address" name="email" required>
              <input type="text" class="form" placeholder="Phone Number" name="phone" required>
            </div>
            <input type="text" class="form" placeholder="Subject" name="subject" required>
            <textarea name="" id="" placeholder="Details" class="form" name="detail" required></textarea>
            <button type="submit" class="button">Submit</button>
          </form>
        </div>
      </div>
    </section>
    <section id="map">
      <div class="section-heading">
        <h1>Our location</h1>
        <p class="text-center">88/166 Ban Glangmuang Urbanion,
          <br/>Jomthong, Bang Kho, Bangkok 10160</p>
      </div>
      <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.0275671384625!2d100.46118931584421!3d13.716780201797182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29840687c3397%3A0x6b8072dab4b8e470!2sWallace+Solutions+Co.%2C+Ltd.!5e0!3m2!1sen!2sth!4v1535294542564"
          width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
      </div>
    </section>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

  .container:first-of-type {
    max-width: 750px;
  }

  .container {
    h1 {
      margin-bottom: 10px;
    }
  }

  .form-container {
    margin-top: 40px;
  }

  .form-container {
    input {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    textarea {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      min-height: 150px;
      resize: vertical;
      max-height: 350px;
    }
    button {
      width: 100%;
    }
  }

  .form-container {
    div {
      display: flex;
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  .form-container {
    div {
      input {
        flex: 1;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  #map {
    padding-bottom: 0;
  }

  .map {
    width: 100%;
    height: 450px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 650px) {
    .form-container {
      div {
        display: block;
        margin-left: 0;
        margin-right: 0;

        input {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .contact-info {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    div {
      flex: 1;
      flex-basis: 50%;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 40px;

      a, p{
        color: $secondary-grey;
      }

      h3 {
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .contact-info {
      display: block;
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      margin-top: 40px;

      div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
</style>
<script>
  export default {
    metaInfo: {
      title: 'Wallace Solutions',
      titleTemplate: '%s | Services'
    }
  }
</script>
